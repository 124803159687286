/**
 *
 * @param {hour} hour
 * @param {endHourFlag} means that it's an hour of turn's end
 * we set begin hour of start turn and end turn, so
 * if turn lasts from 13 to 15 hour, it means, that turn
 * ends at the end of 15'th hour (at 16:00)
 * @returns {hours with offset or null}
 * * check both dates validity, interval between them
 **/
import { HOUR_OFFSET } from "@/config/settings";

function offsetApply(hour = null, endHourFlag = false) {
  // return "13:00" or "00:00"
  if (hour) {
    const res = parseInt(hour) + HOUR_OFFSET + (endHourFlag ? 1 : 0);

    if (res < 24) return (res < 10 ? "0" + res : res) + ":00";
    if (res === 24) return "00:00";
    if (res > 24) return res - 24 + ":00";
  }
  return null;
}

function timeFormat(time, flag = "long") {
  // in: time in format "13:00"
  // in: flag "long" or "short"
  // return "13" or "13:00"
  const hour = time.split(":")[0];
  const minute = time.split(":")[1];
  switch (flag) {
    case "long":
      return hour + ":" + minute;
    case "short":
      return hour;
    default:
      return time;
  }
}

function hoursArr(time_begin, time_end, hours_add, excludeLast = false) {
  // result array for return
  const res = [];
  const midnight = 24;
  let n = parseInt(time_begin.split(":")[0]);
  const hour_end = parseInt(time_end.split(":")[0]);
  if (hour_end >= midnight) return ["error"];
  while (midnight == 24) {
    if (n == midnight) n = 0;
    res.push(n);
    if (n == hour_end) break;
    n++;
  }

  for (let i = 0; i < hours_add; i++) {
    n++;
    if (n == midnight) n = 0;
    res.push(n);
  }

  if (excludeLast) res.length--;

  return res;
}

export { timeFormat, offsetApply, hoursArr };

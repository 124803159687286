<template>
  <div>
    <h3 class="center">Свободные смены</h3>

    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <table class="t_table">
        <tr>
          <th style="width: 5%">№</th>
          <th style="width: 15%">Дата</th>
          <th style="width: 40%">Точка</th>
          <th>Cмена</th>
          <th style="width: 10%">Часов</th>
        </tr>

        <template v-if="total > 0">
          <tr v-for="(row, y) in report" :key="y">
            <td>{{ y + 1 }}</td>
            <td class="center">
              {{ dateFormatHuman(row.turn_date, "w") }}
            </td>
            <td>{{ row.point }}</td>
            <td>
              {{ offsetApply(row.time_begin) }} &mdash;
              {{ offsetApply(row.time_end) }}
              {{ !row.turn_order ? "🌝" : "\u00A0\u00A0\u00A0\u00A0" }}
            </td>
            <td>{{ row.hours }} {{ row.hours_add ? `(+${row.hours_add})` : null }}</td>
          </tr>
          <tr>
            <td colspan="4"></td>
            <td>
              <b>{{ total }}</b>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="300">нет данных</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { timeFormat, offsetApply } from "@/components-js/timeOffset";

export default {
  components: { Loader },
  data() {
    return {
      loading: false,

      report: [],
      total: 0,
    };
  },

  mounted() {
    // retreive data anyway
    this.tableDataRetrieve();
  },

  methods: {
    offsetApply(h /*, l*/) {
      return offsetApply(h /*, l*/);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    async tableDataRetrieve() {
      this.loading = true;

      const data = await request("/api/turn/listfree", "POST");

      this.total = 0;
      for (let i in data) this.total += parseInt(data[i].hours);

      this.report = data;
      this.loading = false;
    },
  },
};
</script>
